import { gsap } from 'gsap';
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';

import '../scss/theme.scss';

export default class MobileMenu {
  constructor() {
    this.container = document.querySelector('.mobile-menu');
    if (!this.container) return;

    this.hamburger = document.querySelector('.menu-icon');
    this.init();
  }

  init() {
    this.hamburger.addEventListener('click', (e) => {
      e.preventDefault();
      e.target.classList.contains('open') ? this.closeMenu() : this.openMenu();
      return false;
    });

    this.openAnimation = gsap
      .timeline({
        paused: true,
        onStart: () => {
          document.body.classList.add('mobile-menu-open');
          this.hamburger.classList.add('open');
          this.container.classList.add('open');
          lock();
        },
      })
      .fromTo(
        this.container,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.25, ease: 'power1.out' }
      )
      //   .fromTo(
      //     this.container.querySelector('.mobile-menu-sign'),
      //     { y: 200 },
      //     { y: 0, duration: 0.7, ease: 'back.out(4)' },
      //     '<'
      //   )
      .fromTo(
        [
          ...this.container.querySelectorAll('.mobile-menu-navigation li'),
          this.container.querySelector('.mobile-menu-footer'),
        ],
        { autoAlpha: 0, y: 15 },
        { autoAlpha: 1, y: 0, stagger: 0.035, duration: 0.45, ease: 'back.out(4)' },
        '<0.1'
      );

    this.closeAnimation = gsap
      .timeline({
        paused: true,
        onStart: () => {
          document.body.classList.remove('mobile-menu-open');
          this.hamburger.classList.remove('open');
          this.container.classList.remove('open');
          clearBodyLocks();
        },
      })
      .to(this.container, { autoAlpha: 0, duration: 0.2, ease: 'power1.out' });

    window.addEventListener('resize', () => this.closeMenu());
  }

  openMenu() {
    this.openAnimation.play(0);
  }

  closeMenu() {
    if (!this.container.classList.contains('open')) return;
    this.closeAnimation.play(0);
  }
}

window.addEventListener('DOMContentLoaded', () => {
  new MobileMenu();
});
